// const environments = {
//   development: 'https://dev.hangicheckup.com/api/',
//   production: 'https://hangicheckup.com/api/',
// };

const socketEnvironments = {
  development: 'https://dev.hangicheckup.com',
  production: 'https://hangicheckup.com',
};

const getCurrentEnvironment = () => {
  return process.env.REACT_APP_HOST_ENV === 'main'
    ? 'production'
    : 'development';
};

// export const getBaseUrl = () => {
//   const currentEnvironment = getCurrentEnvironment();
//   console.log('baseUrl:', environments[currentEnvironment]);
//   return environments[currentEnvironment];
// };

export const getSocketBaseUrl = () => {
  const currentEnvironment = getCurrentEnvironment();
  console.log('socketBaseUrl:', socketEnvironments[currentEnvironment]);
  return socketEnvironments[currentEnvironment];
};
